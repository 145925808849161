<script setup lang="ts">
import Blobs from '~/assets/icons/small-blobs-light.svg?component'

import { cva } from 'class-variance-authority'
import { useNav, toFlagIcon } from './nav'

const nav = await useNav()
const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()
const { locale, localeProperties } = useI18n()

const NuxtLink = resolveComponent('NuxtLink')

const navItemClass = cva([
  'font-medium text-sm text-gray-900 transition h-10 flex items-center px-3 rounded-lg border border-transparent',
  'hover:border-main-100 hover:text-main-900 hover:bg-main-50',
])
</script>

<template>
  <div class="flex flex-row items-center space-x-8">
    <NavigationMenuRoot class="relative flex flex-row items-center justify-center">
      <NavigationMenuList class="flex items-center space-x-1">
        <NavigationMenuItem v-for="item of nav" :key="item.label" class="flex h-10 items-center">
          <template v-if="item.type === 'internal'">
            <NavigationMenuLink as-child class="h-full">
              <NuxtLink :to="item.to" :class="navItemClass()">
                {{ item.label }}
              </NuxtLink>
            </NavigationMenuLink>
          </template>

          <template v-else-if="item.type === 'subnav' || item.type === 'subnav-work'">
            <NavigationMenuTrigger :class="navItemClass()">
              {{ item.label }}
            </NavigationMenuTrigger>

            <NavigationMenuContent class="absolute left-0 top-0 w-auto">
              <div
                class="flex space-x-3 rounded-lg p-6"
                :class="[
                  { 'w-[496px]': item.type !== 'subnav-work' },
                  { 'w-[496px]': item.type === 'subnav-work' && item.links.length > 5 },
                  { 'w-[250px]': item.type === 'subnav-work' && item.links.length <= 5 },
                ]"
              >
                <NavigationMenuLink v-if="item.highlight" as-child>
                  <NuxtLink
                    v-if="item.highlight"
                    :to="item.highlight.to"
                    class="bordershadow-main group relative flex w-1/2 flex-col justify-end overflow-hidden rounded p-4 pt-24 text-sm"
                  >
                    <Blobs
                      class="absolute inset-0 z-0 h-full w-full transform opacity-5 transition group-hover:scale-105 group-hover:opacity-50"
                    />
                    <Heading size="small" class="text-main-950 relative">
                      {{ item.highlight.label }}
                    </Heading>
                    <Paragraph size="small" class="relative text-gray-800">
                      {{ item.highlight.body }}
                    </Paragraph>
                  </NuxtLink>
                </NavigationMenuLink>

                <div
                  :class="[
                    { 'w-1/2': item.highlight },
                    { 'flex flex-col': item.type !== 'subnav-work' },
                    { 'grid w-full': item.type === 'subnav-work' },
                    { 'grid w-full grid-cols-2': item.type === 'subnav-work' && item.links.length > 5 },
                  ]"
                >
                  <NavigationMenuLink v-for="sublink of item.links" :key="sublink.label" as-child>
                    <NuxtLink
                      :to="sublink.to"
                      class="flex items-center space-x-2 rounded px-4 py-3 transition hover:bg-gray-100"
                    >
                      <Paragraph size="small" class="font-medium text-gray-600">
                        {{ sublink.label }}
                      </Paragraph>

                      <LayoutNavNotification v-if="sublink.notification">
                        {{ sublink.notification }}
                      </LayoutNavNotification>
                    </NuxtLink>
                  </NavigationMenuLink>
                </div>
              </div>
            </NavigationMenuContent>
          </template>

          <!-- <template v-else-if="item.type === 'locale'">
            <NavigationMenuTrigger
              :class="navItemClass()"
              :aria-label="$t('header.current_lang') + ' ' + localeProperties.name"
            >
              <Icon :name="toFlagIcon(locale)" size="16px" />
            </NavigationMenuTrigger>

            <NavigationMenuContent class="absolute top-0 w-auto">
              <div class="flex w-64 flex-col space-y-1 rounded-lg bg-white p-4">
                <NavigationMenuLink
                  v-for="link of item.locales.filter((l) => l.locale.code !== locale)"
                  :key="link.locale.code"
                  as-child
                >
                  <NuxtLink
                    :to="switchLocalePath(link.locale.code)"
                    class="flex h-9 items-center justify-between rounded px-3 transition hover:bg-gray-100"
                  >
                    <Paragraph size="small" class="font-medium text-gray-600">
                      <span class="sr-only"> {{ $t('header.switch_to_lang') }}: </span>
                      {{ link.locale.name }}
                    </Paragraph>

                    <Icon :name="toFlagIcon(link.locale.code)" />
                  </NuxtLink>
                </NavigationMenuLink>
              </div>
            </NavigationMenuContent>
          </template> -->
        </NavigationMenuItem>

        <div class="w-2"></div>

        <Action intent="main" size="sm" as="link" :to="localePath('/contact')">
          {{ $t('header.nav.contact') }}
        </Action>
      </NavigationMenuList>

      <div class="perspective-[2000px] absolute right-0 top-full flex min-w-full justify-center">
        <NavigationMenuViewport
          :class="[
            'bordershadow-lg-gray relative mt-2 overflow-hidden rounded-lg bg-white',
            'origin-[top_center] transition-[width,_height] duration-300',
            'data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut',
            'h-[var(--radix-navigation-menu-viewport-height)]',
            'sm:w-[var(--radix-navigation-menu-viewport-width)]',
          ]"
        />
      </div>
    </NavigationMenuRoot>
  </div>
</template>
