<script setup lang="ts">
import { useNav } from './nav/nav'

const nav = await useNav()
const lp = useLocalePath()
const { fetchFooter } = useFooter()

const { data } = await useAsyncData(() => fetchFooter())
</script>

<template>
  <Container v-if="data" as="footer" class="w-full border-t border-gray-200">
    <div class="screen-content grid h-full grid-cols-1 gap-8 px-8 py-4 sm:grid-cols-2 sm:px-16 sm:py-8 lg:grid-cols-4">
      <div class="flex flex-col items-start justify-between gap-y-4 sm:col-span-2 lg:col-span-1">
        <LayoutLogo schema="black" class="h-8 opacity-75" />

        <div class="flex flex-col space-y-4">
          <LayoutFooterSocial />

          <Paragraph size="small" class="!text-gray-500"> © 2016-{{ new Date().getFullYear() }}, Webamboos</Paragraph>
        </div>
      </div>

      <div class="hidden lg:block"></div>

      <div class="flex flex-col gap-y-2">
        <LayoutFooterHeading>
          {{ $t('footer.navigation') }}
        </LayoutFooterHeading>
        <ul class="flex flex-col gap-1.5">
          <template v-for="item of nav">
            <li v-if="item.type === 'internal'">
              <LayoutFooterLink :to="item.to" type="internal">
                {{ item.label }}
              </LayoutFooterLink>
            </li>
            <template v-if="item.type === 'subnav'">
              <li>
                <LayoutFooterLink :to="item.highlight.to" type="internal">
                  {{ item.highlight.label }}
                </LayoutFooterLink>
              </li>
              <li v-for="subItem of item.links">
                <LayoutFooterLink :to="subItem.to" type="internal">
                  {{ subItem.label }}
                </LayoutFooterLink>
              </li>
            </template>
          </template>
          <li>
            <LayoutFooterLink :to="lp('/contact')" type="internal">
              {{ $t('header.nav.contact') }}
            </LayoutFooterLink>
          </li>
        </ul>
      </div>

      <div class="relative flex flex-col gap-y-2">
        <LayoutFooterHeading>
          {{ $t('footer.latest_projects') }}
        </LayoutFooterHeading>

        <NuxtLink
          v-for="project in [...data.projects]
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .slice(0, 3)"
          :key="project.id"
          class="w-full truncate"
          :to="lp(`/projects/${project.industries[0].slug}/${project.slug}`)"
        >
          {{ project.title }}
        </NuxtLink>

        <LayoutFooterHeading class="mt-4">
          {{ $t('footer.latest_articles') }}
        </LayoutFooterHeading>

        <NuxtLink
          v-for="article in [...data.articles]
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .slice(0, 3)"
          :key="article.id"
          class="w-full truncate"
          :to="lp(`/blog/${article.slug}`)"
        >
          {{ article.title }}
        </NuxtLink>
      </div>
    </div>
  </Container>

  <Container
    v-if="data?.badges.length"
    :guides="false"
    class="w-full border-t border-gray-200 bg-gray-50 py-8 md:px-16"
  >
    <LayoutFooterBadges class="md:px-16" />
  </Container>
</template>
