import { default as _91slug_9318RHexAR6WMeta } from "/home/runner/work/webamboos/webamboos/web/pages/blog/[slug].vue?macro=true";
import { default as index56EpO4luSrMeta } from "/home/runner/work/webamboos/webamboos/web/pages/blog/index.vue?macro=true";
import { default as brandQ9QdT9548FMeta } from "/home/runner/work/webamboos/webamboos/web/pages/brand.vue?macro=true";
import { default as _91slug_938ehnErrXoQMeta } from "/home/runner/work/webamboos/webamboos/web/pages/careers/[slug].vue?macro=true";
import { default as indexMUjZKd8HPVMeta } from "/home/runner/work/webamboos/webamboos/web/pages/careers/index.vue?macro=true";
import { default as companyRhQ1JsGeVOMeta } from "/home/runner/work/webamboos/webamboos/web/pages/company.vue?macro=true";
import { default as contactIGQsGK8H9RMeta } from "/home/runner/work/webamboos/webamboos/web/pages/contact.vue?macro=true";
import { default as impressumHa21bWFF3JMeta } from "/home/runner/work/webamboos/webamboos/web/pages/impressum.vue?macro=true";
import { default as index59p4snVHyLMeta } from "/home/runner/work/webamboos/webamboos/web/pages/index.vue?macro=true";
import { default as managed_45projectsX7Cl9pnjDUMeta } from "/home/runner/work/webamboos/webamboos/web/pages/managed-projects.vue?macro=true";
import { default as product_45discovery_45workshopnEWeLTT7GkMeta } from "/home/runner/work/webamboos/webamboos/web/pages/product-discovery-workshop.vue?macro=true";
import { default as _91slug_930aVFegXKK6Meta } from "/home/runner/work/webamboos/webamboos/web/pages/projects/[industry]/[slug].vue?macro=true";
import { default as indexBFKlK7hdhBMeta } from "/home/runner/work/webamboos/webamboos/web/pages/projects/[industry]/index.vue?macro=true";
import { default as indexGnstTyaWPTMeta } from "/home/runner/work/webamboos/webamboos/web/pages/projects/index.vue?macro=true";
import { default as servicesHjVM2zCnOOMeta } from "/home/runner/work/webamboos/webamboos/web/pages/services.vue?macro=true";
import { default as startupsoMMp3afp2CMeta } from "/home/runner/work/webamboos/webamboos/web/pages/startups.vue?macro=true";
import { default as _91slug_93UxCSqhTRo7Meta } from "/home/runner/work/webamboos/webamboos/web/pages/tech/[slug].vue?macro=true";
export default [
  {
    name: _91slug_9318RHexAR6WMeta?.name ?? "blog-slug___en",
    path: _91slug_9318RHexAR6WMeta?.path ?? "/blog/:slug()",
    meta: _91slug_9318RHexAR6WMeta || {},
    alias: _91slug_9318RHexAR6WMeta?.alias || [],
    redirect: _91slug_9318RHexAR6WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: index56EpO4luSrMeta?.name ?? "blog___en",
    path: index56EpO4luSrMeta?.path ?? "/blog",
    meta: index56EpO4luSrMeta || {},
    alias: index56EpO4luSrMeta?.alias || [],
    redirect: index56EpO4luSrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: brandQ9QdT9548FMeta?.name ?? "brand___en",
    path: brandQ9QdT9548FMeta?.path ?? "/brand",
    meta: brandQ9QdT9548FMeta || {},
    alias: brandQ9QdT9548FMeta?.alias || [],
    redirect: brandQ9QdT9548FMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: _91slug_938ehnErrXoQMeta?.name ?? "careers-slug___en",
    path: _91slug_938ehnErrXoQMeta?.path ?? "/careers/:slug()",
    meta: _91slug_938ehnErrXoQMeta || {},
    alias: _91slug_938ehnErrXoQMeta?.alias || [],
    redirect: _91slug_938ehnErrXoQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/careers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMUjZKd8HPVMeta?.name ?? "careers___en",
    path: indexMUjZKd8HPVMeta?.path ?? "/careers",
    meta: indexMUjZKd8HPVMeta || {},
    alias: indexMUjZKd8HPVMeta?.alias || [],
    redirect: indexMUjZKd8HPVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: companyRhQ1JsGeVOMeta?.name ?? "company___en",
    path: companyRhQ1JsGeVOMeta?.path ?? "/company",
    meta: companyRhQ1JsGeVOMeta || {},
    alias: companyRhQ1JsGeVOMeta?.alias || [],
    redirect: companyRhQ1JsGeVOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/company.vue").then(m => m.default || m)
  },
  {
    name: contactIGQsGK8H9RMeta?.name ?? "contact___en",
    path: contactIGQsGK8H9RMeta?.path ?? "/contact",
    meta: contactIGQsGK8H9RMeta || {},
    alias: contactIGQsGK8H9RMeta?.alias || [],
    redirect: contactIGQsGK8H9RMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: impressumHa21bWFF3JMeta?.name ?? "impressum___en",
    path: impressumHa21bWFF3JMeta?.path ?? "/impressum",
    meta: impressumHa21bWFF3JMeta || {},
    alias: impressumHa21bWFF3JMeta?.alias || [],
    redirect: impressumHa21bWFF3JMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: index59p4snVHyLMeta?.name ?? "index___en",
    path: index59p4snVHyLMeta?.path ?? "/",
    meta: index59p4snVHyLMeta || {},
    alias: index59p4snVHyLMeta?.alias || [],
    redirect: index59p4snVHyLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: managed_45projectsX7Cl9pnjDUMeta?.name ?? "managed-projects___en",
    path: managed_45projectsX7Cl9pnjDUMeta?.path ?? "/managed-projects",
    meta: managed_45projectsX7Cl9pnjDUMeta || {},
    alias: managed_45projectsX7Cl9pnjDUMeta?.alias || [],
    redirect: managed_45projectsX7Cl9pnjDUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/managed-projects.vue").then(m => m.default || m)
  },
  {
    name: product_45discovery_45workshopnEWeLTT7GkMeta?.name ?? "product-discovery-workshop___en",
    path: product_45discovery_45workshopnEWeLTT7GkMeta?.path ?? "/product-discovery-workshop",
    meta: product_45discovery_45workshopnEWeLTT7GkMeta || {},
    alias: product_45discovery_45workshopnEWeLTT7GkMeta?.alias || [],
    redirect: product_45discovery_45workshopnEWeLTT7GkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/product-discovery-workshop.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930aVFegXKK6Meta?.name ?? "projects-industry-slug___en",
    path: _91slug_930aVFegXKK6Meta?.path ?? "/projects/:industry()/:slug()",
    meta: _91slug_930aVFegXKK6Meta || {},
    alias: _91slug_930aVFegXKK6Meta?.alias || [],
    redirect: _91slug_930aVFegXKK6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/projects/[industry]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBFKlK7hdhBMeta?.name ?? "projects-industry___en",
    path: indexBFKlK7hdhBMeta?.path ?? "/projects/:industry()",
    meta: indexBFKlK7hdhBMeta || {},
    alias: indexBFKlK7hdhBMeta?.alias || [],
    redirect: indexBFKlK7hdhBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/projects/[industry]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGnstTyaWPTMeta?.name ?? "projects___en",
    path: indexGnstTyaWPTMeta?.path ?? "/projects",
    meta: indexGnstTyaWPTMeta || {},
    alias: indexGnstTyaWPTMeta?.alias || [],
    redirect: indexGnstTyaWPTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: servicesHjVM2zCnOOMeta?.name ?? "services___en",
    path: servicesHjVM2zCnOOMeta?.path ?? "/services",
    meta: servicesHjVM2zCnOOMeta || {},
    alias: servicesHjVM2zCnOOMeta?.alias || [],
    redirect: servicesHjVM2zCnOOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/services.vue").then(m => m.default || m)
  },
  {
    name: startupsoMMp3afp2CMeta?.name ?? "startups___en",
    path: startupsoMMp3afp2CMeta?.path ?? "/startups",
    meta: startupsoMMp3afp2CMeta || {},
    alias: startupsoMMp3afp2CMeta?.alias || [],
    redirect: startupsoMMp3afp2CMeta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/startups.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UxCSqhTRo7Meta?.name ?? "tech-slug___en",
    path: _91slug_93UxCSqhTRo7Meta?.path ?? "/tech/:slug()",
    meta: _91slug_93UxCSqhTRo7Meta || {},
    alias: _91slug_93UxCSqhTRo7Meta?.alias || [],
    redirect: _91slug_93UxCSqhTRo7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/webamboos/webamboos/web/pages/tech/[slug].vue").then(m => m.default || m)
  }
]